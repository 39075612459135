<template>
  <div>
    <h1>Register a new Gen2 Device</h1>
    <form
      class="fc-container-sm fc-flex fc-column fc-align-center">
      <FCInput
        v-model="email"
        type="text"
        title="Email"
        name="Email"
        :value="email" />
      <FCInput
        v-model="serialNumber"
        type="text"
        title="Serial Number"
        name="Serial Number"
        :value="serialNumber"
        @keypress="isLetterOrNumber($event)" />
      <FCInput
        v-model="orderId"
        type="number"
        title="Order ID (ie 5239722082393)"
        name="Order ID"
        :value="orderId" />
      <FCInput
        v-model="notes"
        type="text"
        title="Notes"
        name="Notes" />
      <ButtonAction
        v-show="!success"
        primary
        :disabled="loading && !error"
        @click.native="registerDevice">
        Submit
      </ButtonAction>
    </form>
    <div v-show="success" class="fc-flex">
      <ButtonAction
        class="gen2-registration__success-button"
        @click.native="reset">
        Success! (Press to reset)
      </ButtonAction>
    </div>
    <p v-if="error" class="fc-text-red fc-bold">
      {{ error }}
    </p>
    <div class="fc-container-sm fc-flex fc-column fc-margin-top-10">
      <h2 class="fc-padding-bottom-2">
        Latest Registrations
      </h2>
      <table>
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="`${header}${i}`"
              class="fc-text-left">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="entity in registrations"
            :key="`entity-${entity.email}`"
            class="fc-align-left">
            <td class="fc-text-left fc-padding-left-2">
              {{ entity.email }}
            </td>
            <td class="fc-text-left fc-padding-left-2">
              {{ entity.serialNumber }}
            </td>
          </tr>
        </tbody>
      </table>
      <ButtonAction
        class="fc-margin-top-4"
        @click.native="fetchRegistrations">
        Fetch Latest Registrations
      </ButtonAction>
    </div>
  </div>
</template>

<script>
  import { FCInput } from '@fc/angie-ui';
  import axios from 'axios';
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import http from '@/core/http';

  export default ({
    name: 'Gen2Registration',
    components: {
      FCInput,
      ButtonAction,
    },
    data() {
      return {
        email: '',
        serialNumber: '',
        orderId: null,
        notes: '',
        loading: false,
        success: false,
        error: '',
        headers: ['Email', 'Serial Number'],
        registrations: [],
      };
    },
    methods: {
      async registerDevice() {
        try {
          this.error = '';
          const valid = await this.validateEmail();

          if (!valid) {
            this.error = 'Invalid email address';
            return;
          }

          if (this.orderId !== null && !this.isDigitsOnly(this.orderId)) {
            this.error = 'Order ID must be numbers only';
            return;
          }

          await axios.post('/.netlify/functions/createGen2Registration', {
            email: this.email,
            serialNumber: this.serialNumber,
            orderId: this.orderId,
            notes: this.notes,
            loggedInUser: this.$auth.user.email,
          });

          this.success = true;
        } catch (e) {
          this.error = e.message;
        }
      },
      isDigitsOnly(str) {
        return /^\d+$/.test(str);
      },
      isLetterOrNumber(e) {
        const char = String.fromCharCode(e.keyCode);
        if (/^[A-Za-z0-9]+$/.test(char)) return true;
        return e.preventDefault();
      },
      async validateEmail() {
        if (!this.email) return false;

        try {
          const response = await http.client.post(`${process.env.VUE_APP_API_URL}/validateEmail`, {
            email: this.email,
          });

          return response.data.valid;
        } catch (e) {
          this.error = e.message;
          return false;
        }
      },
      reset() {
        this.email = '';
        this.serialNumber = '';
        this.orderId = null;
        this.notes = '';
        this.loading = false;
        this.success = false;
        this.error = '';
        this.fetchRegistrations();
      },
      async fetchRegistrations() {
        try {
          const response = await axios.get('/.netlify/functions/fetchRegistrations');
          this.registrations = response.data;
        } catch (error) {
          console.error(error);
        }
      },
    },
    mounted() {
      this.fetchRegistrations();
    },
  });
</script>

<style scoped lang="scss">
.gen2-registration {
  &__success-button {
    background-color: green;
  }
}

table {
  border-collapse: collapse;
}
 th {
  background: #ccc;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
}

tr:nth-child(even) {
  background: #efefef;
}

tr:hover {
  background: #d1d1d1;
}
</style>
